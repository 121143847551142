import React from 'react'
import FileSaver from 'file-saver';
import { navigate } from 'gatsby'
import clsx from 'clsx'
import * as Cookies from 'js-cookie';
import GoBack from '../../../../components/pricing/goBack'
import { useDispatch, useSelector } from 'react-redux'
import putPricingState from '../../../../procedures/pricing/putPricingState'
import packPricingObject from '../../../../functions/pricing/packPricingObject'
import ContactUs from '../../../../components/contactUs'
import PRICING_ACTIONS from '../../../../redux/actions/Pricing'
import styles2 from '../../../../components/contactUs/styles.module.css'
import './styles.css'

const PricingDevStepsLayout = () => {
  const dispatch = useDispatch()
  const Pricing = useSelector((state) => state.Pricing)

  const setDevSteps = () => {
    const setAnswer = PRICING_ACTIONS.setDevSteps(1)
    dispatch(setAnswer)

    let pricingObject = packPricingObject(Pricing)
    pricingObject.devSteps = 1
    const idCookie = Cookies.getJSON('opz-pricing-id')
    const id = idCookie ? idCookie.id : null
    if (!id) {
      return () => {
      }
    }
    putPricingState(Pricing.id, pricingObject)
    navigate('/cost-to-make-an-app/samples')
  }

  function download(url) {
    FileSaver.saveAs(url,"file.pdf");
  }


  return (
    <>
      <div className="container">
        <center className={clsx('container', styles2.mycontainer2)}>
          <p className="section__p d-flex justify-content-center flex-column">
            <div>
              <h3 style={{ textAlign: 'center' }}>Your Path to Success</h3>
              The path to quick success with web & mobile app is simple.<br/>
              Here are the steps:<p/>
              <ol>
                <li><h4>User Story Document.</h4>
                  <b>Time:</b> About 1 week.<br/>
                  <b>Price:</b> Starting at <b>$590</b><br/>
                  <b>FREE</b> with the <b>Start App Grant</b> (pun intended). Learn about grant requirements
                  and application process by talking to our expert in the consult below.
                  <p/>
                </li>
                <li><h4>Visual Design</h4>
                  Get a detailed visual design of your app with screenshots. This makes expectations clear
                  and reduces cost and time to a working web/mobile app.<br/>
                  <b>Time:</b> At least 1 week.<br/>
                  <b>Price:</b> Complete Design. Starting at <b>$1,590</b><p/>
                  <center>
                      <button type="button" className="button" onClick={()=>download('https://file.opzoom.com/popcrn/popcrn.v1.4.search.pdf')}>
                         Download Design
                      </button>
                  </center>
                </li>
                <li><h4>Development Plan</h4>
                  Plan the software needed to support the design such as databases & other
                  services. <br/>
                  <b>Time:</b> At least 1 week.<br/>
                  <b>Price:</b> <b>FREE</b> with an OpZoom created design.<br/>
                  Otherwise ask the expert in consult below.<p/><p/>
                  <center>
                      <button type="button" className="button"
                        onClick={()=>download('https://file.opzoom.com/popcrn/popcrn.v1.4.search.doc.pdf')}>
                         Download Plan
                      </button>
                  </center>
                </li>
                <li><h4><i>Optionally</i> Get Funded</h4>
                  Get an investment of up to <b>$100,000</b> from our <b>StartApp Fund</b>.<br/>
                  <b>Requirements:</b> Design and plan must be in place.<br/>
                  <b>Time:</b> At least 1 week.<p/>
                </li>
                <li><h4>Code, Test & Publish</h4>
                  The time for this depends on your design. To succeed you have to put the app in
                  front of real users very quickly.<br/>
                  <b>Time:</b> Recommended time is 2 to 4 weeks.<p/>
                </li>
                <li><h4>App Maintenance & Upgrades</h4>
                  <p>
                    The packages your apps use will be updated regularly because the Apple and Google
                    stores are being constantly updated. We will keep your app working smoothly.
                  </p>
                  <p>
                    Most apps have supporting services such as databases, servers and third party
                    services such as Stripe for payment processing. An estimate for the cost
                    is available in the planning stage.<br/>
                    <b>Time:</b> As long as the app is live.
                  </p>
                </li>
              </ol>
            </div>
          </p>
        </center>


        <center className={clsx('container', styles2.mycontainer2)}>
          <p className="section__p d-flex justify-content-center flex-column">
            <div>
              <h3 style={{ textAlign: 'center' }}>Sample Apps.</h3>
              <p>
                Our sample apps includes:
              <ol>
                <li>Apps released under the OpZoom Brand.</li>
                <li>A few that have nearly <b>1 Million</b> downloads.</li>
                <li>This includes web and mobile apps</li>
              </ol>
              </p>
            </div>
          </p>
          <center>
            <button type="button" className="button" onClick={() => {
              setDevSteps()
            }}>
              See Sample Apps
            </button>
          </center>
        </center>

        {(Pricing.verifyCount === 0) && <ContactUs/>}

      </div>

      <GoBack prevQuestionId={'more'}/>
    </>
  )
}
export default PricingDevStepsLayout
